// Header.js
import React, { useState } from "react";
import { AppBar, Toolbar, Button, IconButton, Drawer, List, ListItem, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawer = (
    <div onClick={handleDrawerToggle}>
      <List>
        <ListItem button component={Link} to="/" className="active-font">
          <ListItemText primary="Home" classes={{ primary: 'active-font' }} />
        </ListItem>
        <ListItem button component={Link} to="/getstarted" className="active-font">
          <ListItemText primary="Get Started" classes={{ primary: 'active-font' }} />
        </ListItem>
        {/* <ListItem button component={Link} to="/about-us" className="active-font">
          <ListItemText primary="About Us" classes={{ primary: 'active-font' }} />
        </ListItem> */}
        <ListItem button component={Link} to="/login" className="active-font">
          <ListItemText primary="Login" classes={{ primary: 'active-font' }} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <AppBar position="fixed" style={{ backgroundColor: "white" }}>
      <Toolbar style={{ justifyContent: isMobile ? "space-between" : "center" }}>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              style={{ color: "black" }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
              {drawer}
            </Drawer>
          </>
        ) : (
          <>
            <Button
              component={Link}
              to="/"
              color="inherit"
              underline="hover"
              className="active-font"
              style={{ color: "black" }}
            >
              Home
            </Button>
              <Button
              component={Link}
              to="/getstarted"
              variant="outlined"
              className="active-font"
              style={{
                backgroundColor: "#000000", // Set background color to black
                color: "white", // Set text color to white
                borderColor: "#000000"
              }}
            >
              Get Started
            </Button>
            {/* <Button
              component={Link}
              to="/about-us"
              color="inherit"
              underline="hover"
              className="active-font"
              style={{ color: "black" }}
            >
              About Us
            </Button> */}
            <Button
              component={Link}
              to="/login"
              color="inherit"
              underline="hover"
              className="active-font"
              style={{ color: "black" }}
            >
            Login
            </Button>
            {/* Add other buttons here */}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
