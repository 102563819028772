import React, { useState } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo.svg';
import successImage from '../images/successMark.svg';
import Header from '../components/Header';
import Footer from '../components/Footer';
import VitalQueTextField from '../components/VitalQueTextField';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, updatePassword  } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState('');
    const [sentEmail, setSentEmail] = useState('');
    const navigate = useNavigate();

    const resetPassword = async () => {
        try {
            // Sign in the user with current email and password
            const userCredential = await signInWithEmailAndPassword(auth, email, currentPassword);
            const user = userCredential.user;

            // Update the password for the signed-in user
            await updatePassword(user, newPassword);
            setError('')
            setIsSuccess(true);
            setSentEmail(email);
            setTimeout(() => navigate('/login'), 2000); // Redirect to login after 2 seconds
        } catch (err) {
            console.error("Error updating password:", err); // Log the full error object

            // Handle specific errors
            switch (err.code) {
                case 'auth/wrong-password':
                    setError("Incorrect current password. Please try again.");
                    break;
                case 'auth/weak-password':
                    setError("The new password is too weak. It should be at least six characters.");
                    break;
                case 'auth/user-not-found':
                    setError("No user found with this email. Please check and try again.");
                    break;
                default:
                    setError("Failed to update your password. Please try again.");
                    break;
            }
            setIsSuccess(false);
        }
    };


    return (
        <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={12} container direction="column" alignItems="center" style={{ marginBottom: '200px' }}>
                <Header />
            </Grid>
            <Grid item xs={12} container direction="column" alignItems="center">
                <div style={{ textAlign: 'center' }}>
                    <img src={logo} alt="Logo" style={{ maxWidth: '100%', height: 'auto' }} />
                    <Typography variant="h1" align="center" className="active-font" style={{ color: '#9B9B9B' }}>
                        Vital<span style={{ color: '#4BD891' }}>Que</span>
                    </Typography>
                </div>
                <form>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <VitalQueTextField 
                                label="Email" 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)} 
                                name="email"
                            />
                            <VitalQueTextField 
                                label="Current Password" 
                                type="password" 
                                value={currentPassword} 
                                onChange={(e) => setCurrentPassword(e.target.value)} 
                                name="currentPassword"
                            />
                            <VitalQueTextField 
                                label="New Password" 
                                type="password" 
                                value={newPassword} 
                                onChange={(e) => setNewPassword(e.target.value)} 
                                name="newPassword"
                            />
                            {isSuccess && (
                                <div style={{ marginTop: '10px' }}>
                                    <Typography variant="caption" align="center" className="basefont-regular" style={{ color: 'black' }}>
                                        Password has been successfully reset for {sentEmail}.
                                    </Typography>
                                </div>
                            )}
                            {error && (
                                <Typography variant="caption" align="center" className="basefont-regular" style={{ color: 'red', marginTop: '10px' }}>
                                    {error}
                                </Typography>
                            )}
                            <Button
                                variant="outlined"
                                className="active-font"
                                onClick={resetPassword}
                                style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    borderColor: "#4BD891",
                                    color: "black", // Text color
                                    fontSize: "1.5rem",
                                    minWidth: "180px",
                                }}
                            >
                                Reset Password
                            </Button>
                        </div>
                    </div>
                </form>
            </Grid>
            <Footer />
        </Grid>
    );
};

export default ForgotPasswordPage;
