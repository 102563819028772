import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Box, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import EOBConverter from './eob-to-era-converter';  // Import the EOB-ERA Converter component
import MenuPane from '../components/MenuPane';
import Footer from '../components/Footer';

const DashboardHomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedTool, setSelectedTool] = useState(null);

  // Toggle drawer visibility
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Handle tool selection
  const handleToolSelect = (tool) => {
    setSelectedTool(tool);
    setDrawerOpen(false); // Close the drawer when a tool is selected
  };

  return (
    <Grid container direction="column" style={{ minHeight: '100vh', backgroundColor: '#F9FAFB' }}>
      <MenuPane/>
      <Button
        onClick={toggleDrawer}
        className="active-font"
        style={{
          marginTop: '100px',
          marginLeft: '50px',
          backgroundColor: '#000',
          color: '#FFF',
          maxWidth: '240px',
          fontSize: '1.5rem',
        }}
        startIcon={<MenuIcon />}
      >
        Tools
      </Button>

      {/* Sidebar Drawer */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        style={{ zIndex: 1000 }}
      >
        <Box sx={{ width: 250, marginTop: 10 }} role="presentation">
          <List>
            <ListItem button className="basefont-regular" onClick={() => handleToolSelect('EOB-ERA Converter')}>
              <ListItemText  className="basefont-regular" primary="EOB-ERA Converter" />
            </ListItem>
            {/* You can add more tools here in the future */}
          </List>
        </Box>
      </Drawer>

       <Grid
          item
          container
          direction="column"
          style={{
            flexGrow: 1,
            display: 'flex',
            padding: '16px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* Conditionally render content based on selected tool */}
          {selectedTool === 'EOB-ERA Converter' ? (
            <EOBConverter />
          ) : (
            <Typography variant="body 1" className='basefont-regular' style={{ textAlign: 'center', color: '#6B7280' }}>
              Select a tool from the tool drawer to start. We are still in development mode and more tools are on the way!
            </Typography>
          )}
        </Grid>

      <Footer/>
    </Grid>
  );
};

export default DashboardHomePage;
