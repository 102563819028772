import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Box, LinearProgress } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import axios from 'axios';
import "../landing-page/HomePage.css";
import logo from '../images/logo.svg';
import MenuPane from '../components/MenuPane';
import Footer from '../components/Footer';

const EOBConverter = () => {
  const [files, setFiles] = useState([]);
  const [ocrInProgress, setOcrInProgress] = useState(false);
  const [ocrResults, setOcrResults] = useState('');
  const [instructionsVisible, setInstructionsVisible] = useState(true);
  const [openAiResponse, setOpenAiResponse] = useState('');
  const [progress, setProgress] = useState(0);
  const [currentChunk, setCurrentChunk] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Handle file input
  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  // Handle file upload and OCR
  const handleUpload = async () => {
    setOcrInProgress(true);
    setOcrResults('');
    let allOcrText = ''; // Collect all OCR text from all files
    let chunkIndex = 0; // Track which chunk is being processed

    for (const file of files) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        try {
          // Convert image to base64
          const base64Image = reader.result.split(',')[1];

          // Send to Google Vision API for OCR
          const apiKey = process.env.REACT_APP_GOOGLE_CLOUD_API_KEY;

          const response = await axios.post(
            `https://vision.googleapis.com/v1/images:annotate?key=${apiKey}`,
            {
              requests: [
                {
                  image: { content: base64Image },
                  features: [{ type: 'TEXT_DETECTION' }],
                },
              ],
            }
          );

          const extractedText = response.data.responses[0].fullTextAnnotation.text;

          // Collect all OCR texts into a single string (to send in one OpenAI request)
          allOcrText += `\n\nExtracted Text from ${file.name}:\n${extractedText}`;
        } catch (error) {
          console.error('Error with OCR processing:', error);
        } finally {
          if (files.indexOf(file) === files.length - 1) {
            // After the last file is processed, call OpenAI API
            await processWithOpenAI(allOcrText);
          }
        }
      };
      reader.readAsDataURL(file); // Convert image to base64
    }
  };

  const processWithOpenAI = async (ocrText) => {
    const chunkSize = 8000; // Adjust the chunk size based on the model's token limit and OCR text length
    let results = [];
  
    // Split OCR text into smaller chunks
    const chunks = chunkText(ocrText, chunkSize);
  
    // Introduction message for OpenAI
    const introductionMessage = {
      role: "system",
      content: "You are an expert medical billing assistant, proficient in processing Explanation of Benefits (EOB) documents and converting them into a single ERA file format (X12 835). " +
               "The OCR content I will provide you is split into smaller chunks, but it all comes from the same EOB document. " +
               "Your task is to process these chunks and generate a single unified ERA file, even though the data is in multiple parts. If there are copies of the same EOB then ignore the additional copies."
    };
  
    // Create the messages to send to OpenAI
    const messages = [introductionMessage];
  
    // Add each chunk to the message
    for (let i = 0; i < chunks.length; i++) {
      setCurrentChunk(`Processing chunk ${i + 1} of ${chunks.length}`);
      setProgress(((i + 1) / chunks.length) * 100); // Update progress
  
      const chunk = chunks[i];
      // Send each chunk to OpenAI with the additional context of it being part of the same document
      messages.push({
        role: "user",
        content: `Here is chunk ${i + 1} of the OCR data from the same EOB document:\n\n${chunk}`
      });
    }
  
    // Send the combined message to OpenAI to process the full document
    const finalEraResponse = await sendRequestWithRetry(messages, results);
  
    // After processing all chunks, combine results into one ERA file
    setOpenAiResponse(finalEraResponse);
    setProgress(100);
    setCurrentChunk('Done');
   setOcrInProgress(false);
  };
  
  // Function to send the request with retry logic
  const sendRequestWithRetry = async (messages, results) => {
    const retries = 5; // Number of retries
    const delay = 1000; // Delay between retries in ms
    let attempt = 0;
  
    while (attempt < retries) {
      try {
        const openAiApiKey = process.env.REACT_APP_OPENAI_API_KEY;
  
        const response = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
            model: 'gpt-4',
            messages: messages,
            max_tokens: 1000,
            temperature: 0.1,
          },
          {
            headers: {
              'Authorization': `Bearer ${openAiApiKey}`,
              'Content-Type': 'application/json',
            },
          }
        );
  
        const openAiText = response.data.choices[0].message.content;
        results.push(openAiText);  // Collect results for all chunks
  
        break; // Exit loop if the request is successful
      } catch (error) {
        if (error.response && error.response.status === 429) {
          // Retry if rate limit is exceeded
          console.log(`Rate limit hit, retrying in ${delay / 1000} seconds...`);
          await new Promise((resolve) => setTimeout(resolve, delay)); // Wait before retrying
          attempt++;
          if (attempt === retries) {
            console.error('Max retries reached');
          }
        } else {
          console.error('Error with OpenAI request:', error);
          break;
        }
      }
    }
  
    return results.join("\n"); // Combine all results into a single string for the final output
  };
  

  // Function to split large text into smaller chunks
  const chunkText = (text, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < text.length; i += chunkSize) {
      chunks.push(text.slice(i, i + chunkSize));
    }
    return chunks;
  };

  // Function to trigger download of ERA file
  const downloadEraFile = () => {
    const blob = new Blob([openAiResponse], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'ERA_File.txt';
    link.click();
  };

  return (
    <Grid container direction="column" style={{ minHeight: '100vh', backgroundColor: '#F9FAFB' }}>
      <Grid
        item
        container
        direction="column"
        style={{
          flexGrow: 1,
          display: 'flex',
          marginTop: '75px',
          padding: '16px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '20px' }}>
          <img src={logo} alt="VitalQue Logo" style={{ maxWidth: '100%', height: 'auto', marginBottom: '20px' }} />
          <Typography variant="h3" className="basefont-regular" style={{ color: '#1F2937', fontWeight: 'bold' }}>
            Upload Your EOB Data
          </Typography>
          <Typography variant="h6" className="basefont-regular" style={{ color: '#6B7280', marginTop: '10px' }}>
            This tool allows you to take photos of EOB documents from a payor and combine them into a single ERA file for a provider. 
          </Typography>
        </Grid>

        {instructionsVisible && (
          <Box
            sx={{
              backgroundColor: '#FFFFFF',
              borderRadius: '8px',
              padding: '20px',
              boxShadow: 2,
              width: '100%',
              maxWidth: '600px',
              textAlign: 'left',
              marginBottom: '30px',
            }}
          >
            <Typography variant="h6" className="basefont-regular" style={{ color: '#1F2937' }}>
              Step 1: Capture Your EOB
            </Typography>
            <Typography variant="body1" className="basefont-regular" style={{ color: '#6B7280', marginTop: '10px' }}>
              Please take clear photos of your Explanation of Benefits (EOB) documents. Make sure the text is legible and free from glare or blur.
            </Typography>
            <Typography variant="h6" className="basefont-regular" style={{ color: '#1F2937', marginTop: '20px' }}>
              Step 2: Upload the Images
            </Typography>
            <Typography variant="body1" className="basefont-regular" style={{ color: '#6B7280', marginTop: '10px' }}>
              Once you've taken the photos, upload them here for OCR processing.
            </Typography>
          </Box>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button
            component="label"
            className="active-font"
            variant="outlined"
            style={{
              marginTop: "10px",
              backgroundColor: "#000000", // Black background for action button
              color: "white", // White text
              borderColor: "#D1D5DB", // Light gray border
              fontSize: "1.5rem",
              textTransform: "none", // Retain original text case
              padding: "12px 30px", // Larger padding for better visual appeal
            }}
            startIcon={<CloudUploadIcon />}
          >
            Upload EOB Images
            <input
              type="file"
              hidden
              accept="image/*"
              multiple
              onChange={handleFileChange}
            />
          </Button>

          {files.length > 0 && (
            <Typography variant="body1" className="basefont-regular" style={{ color: '#000000', marginBottom: '20px' }}>
              {files.length} file(s) selected
            </Typography>
          )}

          {ocrInProgress ? (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="body1" className="basefont-regular" style={{ color: '#000000' }}>
                OCR Processing in Progress...
              </Typography>
              <LinearProgress variant="determinate" value={progress} sx={{ width: '100%' }} />
              <Typography variant="body1" className="basefont-regular" style={{ color: '#000000', marginTop: '10px' }}>
                {currentChunk}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="body1" className="basefont-regular" style={{ color: '#000000' }}>
                {ocrResults}
              </Typography>
            </Box>
          )}

          <Button
            variant="outlined"
            className="active-font"
            style={{
              marginTop: "10px",
              backgroundColor: "#000000", // Black background for action button
              color: "white", // White text
              borderColor: "#D1D5DB", // Light gray border
              fontSize: "1.5rem",
              textTransform: "none", // Retain original text case
              padding: "12px 30px", // Larger padding for better visual appeal
            }}
            onClick={handleUpload}
          >
            Convert to ERA
          </Button>

          {/* Display OpenAI Response */}
          {openAiResponse && (
            <Box sx={{ textAlign: 'center', marginTop: '20px' }}>

              <Button
                variant="outlined"
                onClick={downloadEraFile}
                style={{
                  marginTop: "10px",
                  backgroundColor: "green", // Black background for download button
                  color: "white", // White text
                  borderColor: "#D1D5DB", // Light gray border
                  fontSize: "1.5rem",
                  textTransform: "none", // Retain original text case
                  padding: "12px 30px", // Larger padding for better visual appeal
                }}
              >
                Download Completed ERA File
              </Button>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default EOBConverter;
