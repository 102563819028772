import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Button, IconButton, Drawer, List, ListItem, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { doc, getDoc, getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth"; 
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const MenuPane = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [homeRoute, setHomeRoute] = useState("/"); // Default route
  const [showError, setShowError] = useState(""); // Holds detailed error messages
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = async () => {
    const auth = getAuth(); // Get the Firebase auth instance
    try {
      await signOut(auth); // Sign out from Firebase
    } catch (error) {
      setShowError(`Error logging out: ${error.message}`); // Handle any errors
    }
  };

  const drawer = (
    <div onClick={handleDrawerToggle}>
      <List>
        <ListItem button component={Link} to="/home" className="active-font">
          <ListItemText primary="Home" classes={{ primary: 'active-font' }} />
        </ListItem>
        <ListItem button component={Link} to="/settings" className="active-font">
          <ListItemText primary="Settings" classes={{ primary: 'active-font' }} />
        </ListItem>
        <ListItem button component={Link} onClick={handleLogout} to="/login" className="active-font">
          <ListItemText primary="Logout" classes={{ primary: 'active-font' }} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <AppBar position="fixed" style={{ backgroundColor: "white" }}>
      <Toolbar style={{ justifyContent: isMobile ? "space-between" : "center" }}>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              style={{ color: "black" }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
              {drawer}
            </Drawer>
          </>
        ) : (
          <>
            <Button
              component={Link}
              to="/home"
              color="inherit"
              className="active-font"
              style={{ color: "black" }}
            >
              Home
            </Button>
            <Button
              component={Link}
              to="/settings"
              color="inherit"
              className="active-font"
              style={{ color: "black" }}
            >
              Settings
            </Button>
            <Button
              component={Link}
              onClick={handleLogout}
              to="/login"
              color="inherit"
              className="active-font"
              style={{ color: "black" }}
            >
              Logout
            </Button>
          </>
        )}
      </Toolbar>
      {showError && <p style={{ color: 'red' }}>{showError}</p>}
    </AppBar>
  );
};

export default MenuPane;
