import React, { useEffect } from "react";
import "./HomePage.css";
import { Grid, Typography, Button, Divider, Box } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../images/logo.svg";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";

const HomePage = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  return (
    <Grid
      container
      spacing={0}
      justifyContent="center"
      alignItems="center"
      className="body"
      style={{ minHeight: "100vh", backgroundColor: "#ffffff" }} // Primary background color
    >
      <Grid item xs={12} container direction="column" alignItems="center" style={{ marginBottom: "100px" }}>
        <Header />
      </Grid>

      <Grid item xs={12} container direction="column" alignItems="center">
        <div style={{ textAlign: "center" }}>
          <img src={logo} alt="Logo" style={{ maxWidth: "100%", height: "auto" }} />
          <Typography variant="h1" align="center" className="active-font" style={{ color: "#000000" }}>
            Vital<span style={{ color: "#000000" }}>Que</span>
          </Typography>
          <Typography variant="h4" align="center" className="active-font" style={{ color: "#6B7280" }}>
            Revolutionizing Medical Billing with AI-Powered Automation.
          </Typography>
        </div>

        <Button
          component={Link}
          to="/getstarted"
          variant="outlined"
          className="active-font"
          style={{
            marginTop: "10px",
            backgroundColor: "#000000", // Black background for action button
            color: "white", // White text
            borderColor: "#D1D5DB", // Light gray border
            fontSize: "1.5rem",
            textTransform: "none", // Retain original text case
            padding: "12px 30px", // Larger padding for better visual appeal
          }}
        >
          Get Started
        </Button>

        <Button
          component={Link}
          to="/login"
          variant="text"
          className="active-font"
          style={{
            marginTop: "10px",
            color: "#000000", // Black text
            border: "none", // No border
            backgroundColor: "transparent", // Transparent background
            fontSize: "1.5rem",
            textDecoration: "underline", // Underlined text
            textTransform: "none", // Retain original text case
          }}
        >
          Login
        </Button>
      </Grid>

      <Grid item xs={12} container direction="column" alignItems="center">
        <Typography variant="h3" align="center" className="active-font" style={{ color: "#000000", marginTop: "150px" }}>
          What Do We Do?
        </Typography>
        <Typography
          variant="h6"
          align="center"
          className="basefont-regular"
          style={{
            color: "#000000",
            marginTop: "20px",
            maxWidth: "535px",
            padding: "10px",
            marginBottom: "40px",
            backgroundColor: "#e5e7eb", // Light gray background for detail text area
            borderRadius: "8px", // Rounded corners
          }}
        >
          We leverage AI technology to automate the medical billing process. Our solution reduces errors, increases efficiency, and ensures a smoother billing cycle for healthcare providers.
        </Typography>
      </Grid>

      {/* Use Cases Section */}
      <Grid item xs={12} container direction="column" alignItems="center" style={{ backgroundColor: "#f3f4f6", padding: "50px 0" }}>
        <Typography variant="h3" align="center" className="active-font" style={{ color: "#000000" }}>
          Use Cases
        </Typography>
        <Typography
          variant="h6"
          align="center"
          className="basefont-medium"
          style={{
            color: "#000000",
            marginTop: "20px",
            maxWidth: "700px",
            padding: "10px",
            marginBottom: "40px",
          }}
        >
          AI-powered automation in medical billing can address several pain points in healthcare billing systems. Here are some key use cases:
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
            <Typography variant="h6" className="active-font" style={{ color: "#000000" }}>
              EOB to ERA Conversion
            </Typography>
            <Typography variant="body1" className="basefont-regular" style={{ color: "#6B7280", marginTop: "10px" }}>
              Convert Explanation of Benefits (EOB) documents into Electronic Remittance Advice (ERA) files, automating the posting process.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
            <Typography variant="h6" className="active-font" style={{ color: "#000000" }}>
              Insurance Payment Posting
            </Typography>
            <Typography variant="body1" className="basefont-regular" style={{ color: "#6B7280", marginTop: "10px" }}>
              Automate the process of posting insurance payments to patient accounts, ensuring accuracy and timely updates.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
            <Typography variant="h6" className="active-font" style={{ color: "#000000" }}>
              Error Reduction & Efficiency
            </Typography>
            <Typography variant="body1" className="basefont-regular" style={{ color: "#6B7280", marginTop: "10px" }}>
              Use AI to reduce errors in manual data entry, speeding up the billing cycle and improving overall operational efficiency.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Visual Impact Section */}
      <Grid item xs={12} container direction="column" alignItems="center" style={{ padding: "50px 0" }}>
        <Typography variant="h3" align="center" className="active-font" style={{ color: "#000000" }}>
          Immediate Impact
        </Typography>
        <Typography
          variant="h6"
          align="center"
          className="basefont-medium"
          style={{
            color: "#000000",
            marginTop: "20px",
            maxWidth: "700px",
            padding: "10px",
            marginBottom: "40px",
          }}
        >
          Our AI-driven solution simplifies and automates tasks traditionally done manually.
        </Typography>
      </Grid>

      <Footer />
    </Grid>
  );
};

export default HomePage;
