import React, { useState, useEffect } from "react";
import "./HomePage.css";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo from "../images/logo.svg";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";
import VitalQueTextField from "../components/VitalQueTextField.js";
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const ContactPage = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState(""); // Password field
  const [confirmPassword, setConfirmPassword] = useState(""); // Confirm password field

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();

  const handleSignup = async () => {
    try {
      setLoading(true); 

      if (!firstName || !lastName || !email || !phoneNumber || !companyName || !password || !confirmPassword) {
        setErrorMessage("All fields are required.");
        setShowSuccess(false);
        return;
      }

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(email)) {
        setErrorMessage("Invalid email format.");
        setShowSuccess(false);
        return;
      }

      const phonePattern = /^\d{10}$/;
      if (!phonePattern.test(phoneNumber)) {
        setErrorMessage("Invalid phone number format.");
        setShowSuccess(false);
        return;
      }

      if (password !== confirmPassword) {
        setErrorMessage("Passwords do not match.");
        setShowSuccess(false);
        return;
      }

      // Sign up the user
      await createUserWithEmailAndPassword(auth, email, password);

      // Clear form fields
      setFirstname("");
      setLastname("");
      setEmail("");
      setPhoneNumber("");
      setCompanyName("");
      setPassword("");
      setConfirmPassword("");

      // Show success message and navigate to login page
      setErrorMessage("");
      setSuccessMessage("Signup successful! Redirecting to login...");
      setShowSuccess(true);
      
      // Redirect to login page after 3 seconds
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      console.error('Error during signup:', error);
      setErrorMessage(`Error during signup. Please try again later. ${error.message}`);
      setShowSuccess(false);
    } finally {
      setLoading(false); // Set loading to false when form submission ends
    }
  };

  return (
    <Grid
      container
      spacing={0}
      justify="center"
      alignItems="center"
      style={{ minHeight: "100vh", padding: "4px" }}
    >
      <Grid
        item
        xs={12}
        container
        direction="column"
        alignItems="center"
        style={{ marginBottom: "200px" }}
      >
        <Header />
        <Grid item xs={12} container direction="column" alignItems="center" style={{ maxWidth: "350px" }}>
          <div style={{ textAlign: "center", marginTop: "150px" }}>
            <img
              src={logo}
              alt="Logo"
              style={{ maxWidth: "100%", height: "auto" }}
            />
            <Typography
              variant="h3"
              align="center"
              className="active-font"
              style={{ color: "black" }}
            >
              Sign Up
            </Typography>

            <div style={{ height: "20px", maxWidth: "300px" }}></div>
            <VitalQueTextField
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstname(e.target.value)}
            />
            <VitalQueTextField
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastname(e.target.value)}
            />
            <VitalQueTextField
              label="Work Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <VitalQueTextField
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <VitalQueTextField
              label="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <VitalQueTextField
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <VitalQueTextField
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            {showSuccess || errorMessage ? (
              <div>
                {errorMessage ? (
                  <div>
                    <Typography
                      variant="body1"
                      align="center"
                      className="basefont-thin !important"
                      style={{ color: "red" }}
                    >
                      {errorMessage}
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <Typography
                      variant="body1"
                      align="center"
                      className="basefont-thin !important"
                      style={{ color: "green" }}
                    >
                      {successMessage}
                    </Typography>
                  </div>
                )}
              </div>
            ) : null}

            <Button
              variant="outlined"
              className="active-font"
              onClick={handleSignup}
              style={{
                marginTop: "10px",
                backgroundColor: "#000000", // Black background for action button
                color: "white", // White text
                borderColor: "#D1D5DB", // Light gray border
                fontSize: "1.0rem",
              }}
              disabled={loading} // Disable button when loading
            >
              {loading ? "Submitting..." : "Sign Up"}
            </Button>
          </div>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default ContactPage;
