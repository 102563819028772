// Footer.js
import React from "react";
import Typography from "@mui/material/Typography";

const Footer = () => {
  return (
    <div style={{ borderTop: "2px solid #6B7280", textAlign: "center", width: "100%" }}>
      <Typography variant="body2" color="black" className="basefont-thin" style={{ paddingTop: "20px" }}>
        Copyright © 2024 VitalQue. All rights reserved.
      </Typography>
      <Typography variant="body2" color="black" className="basefont-thin" style={{ paddingTop: "10px" }}>
        230 E Ohio Street Suite 410 1001 Chicago, IL 60611
      </Typography>
      <Typography variant="body2" color="textSecondary" className="basefont-thin">
        <a href="/privacypolicy" style={{ textDecoration: "none", color: "#9B9B9B", paddingBottom: "20px" }}>
          Privacy Policy
        </a>
      </Typography>
    </div>
  );
};

export default Footer;
